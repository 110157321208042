import React from 'react'
import Img from 'gatsby-image'
import pageQuery from '../../pageQuery'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import dataJson from './assets/data/data.json'
import Stepper from './Stepper/_Stepper'
import stepperData from './assets/data/stepperData.json'
// Styles
import { ContainerBackground, OrangeCircle, ImgDiv, Button } from './style'

type CardsType = {
  id: string;
  title: string;
  description: string;
}

type ComoAgendarUmaConsultaOnlineProps = {
  setIsOpen: Function;
};

const ComoAgendarUmaConsultaOnline = ({ setIsOpen }: ComoAgendarUmaConsultaOnlineProps) => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const cards: CardsType[] = dataJson

  return (
    <section
      id='muito-mais-que-um-banco-digital'
      className='pt-5 pb-5 pb-lg-0 mh-auto bg-white'
    >
      <ContainerBackground className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-3 mb-md-4'>Como agendar uma consulta online?</h2>
            <p className='fs-14 lh-16 fs-lg-18 lh-lg-22 text-grayscale--400 mb-3 mb-md-4'>Doutor Inter é exclusivo para clientes Inter, siga o passo a passo <span className='d-md-block'>para abrir sua conta, contratar e agendar sua consulta de forma 100% digital.</span></p>
          </div>
          <div className='col-12 d-lg-none pt-md-5'>
            <ImgDiv>
              <Img fluid={data.agendeUmaConsultaOnline.fluid} alt='Tela de um smartphone mostrando a página do Doutor Inter no Super App do Inter.' />
            </ImgDiv>
          </div>
          <div className='col-12 col-lg-6 offset-lg-6 d-md-none'>
            <DefaultCarousel
              sm={{ items: 1 }}
            >
              {
            cards.map((item: CardsType) => (
              <div key={item.id} className='d-flex'>
                <div className='mr-4'>
                  <OrangeCircle className='d-flex align-items-center justify-content-center'>{item.id}</OrangeCircle>
                </div>
                <div>
                  <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mb-2' dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))
          }
            </DefaultCarousel>
          </div>
          <div className='col-12 col-lg-6 offset-lg-6 mt-4 pr-xl-0'>
            <div className='d-none d-md-block'>
              <Stepper data={stepperData} />
            </div>
            <Button className='text-md-center mt-lg-4 pb-lg-5'>
              <a
                title='Contratar Doutor Inter'
                className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none d-md-none'
                href='https://inter-co.onelink.me/Qyu7/kuarwbdk'
                target='_blank'
                rel='noreferrer'
                onClick={() => sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Como agendar uma consulta online?',
                  element_action: 'click button',
                  element_name: 'Contratar Doutor Inter',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/kuarwbdk',
                })}
              >
                Contratar Doutor Inter
              </a>
              <button
                title='Contratar Doutor Inter'
                className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none d-none d-md-flex mx-auto'
                onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Como agendar uma consulta online?',
                    element_action: 'click button',
                    element_name: 'Contratar Doutor Inter',
                  })
                }}
              >
                Contratar Doutor Inter
              </button>
            </Button>
          </div>
        </div>
      </ContainerBackground>
    </section>
  )
}

export default ComoAgendarUmaConsultaOnline
